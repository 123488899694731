import React from "react";
import { Link } from "gatsby";
import { css } from "@emotion/core";
import { Heading2, Heading5 } from "./Text";
import { bpLarge, colorLight, bpMedium, bpSmall } from "../../stylingConstants";
import GatsbyImage, { FluidObject } from "gatsby-image";
import MaybeExternalLink from "./MaybeExternalLink";

type Props = {
  title: string;
  href: string;
  imageSrc?: FluidObject | null;
  publishDate: string;
  preamble?: string | null;
  target?: string;
};

const cardCss = css`
  display: flex;
  margin: 28px 0;
  flex-direction: column;
  @media ${bpSmall} {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
  @media ${bpMedium} {
    flex-direction: column;
  }
  @media ${bpLarge} {
    margin: 48px 0;
  }
`;

export default function NewsListItem({
  title,
  href,
  imageSrc,
  preamble,
  publishDate,
  ...props
}: Props) {
  return (
    <MaybeExternalLink to={href} css={cardCss} {...props}>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          box-sizing: border-box;
          width: 100%;
          @media ${bpSmall} {
            flex-direction: column-reverse;
            justify-content: flex-end;
            width: 50%;
            ${imageSrc != null &&
            css`
              padding-left: 20px;
            `}
          }
          @media ${bpMedium} {
            flex-direction: column;
            padding-left: unset;
            width: 100%;
          }
        `}
      >
        {preamble && (
          <p
            css={css`
              display: none;
              @media ${bpSmall} {
                display: block;
              }
              @media ${bpMedium} {
                display: none;
              }
            `}
          >
            {preamble}
          </p>
        )}
        <Heading2
          css={css`
            margin-bottom: 12px;
          `}
        >
          {title}
        </Heading2>
        {publishDate && (
          <Heading5
            css={css`
              margin-bottom: 16px;
              color: ${colorLight};
            `}
          >
            {publishDate}
          </Heading5>
        )}
      </div>
      {imageSrc && (
        <img
          src={imageSrc.src}
          srcSet={imageSrc.srcSet}
          css={css`
            box-sizing: border-box;
            width: 100%;
            object-fit: contain;
            @media ${bpSmall} {
              width: 50%;
              padding-right: 12px;
            }
            @media ${bpMedium} {
              padding-right: unset;
              width: 100%;
            }
          `}
        />
      )}
    </MaybeExternalLink>
  );
}
