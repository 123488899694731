import { graphql, Link } from "gatsby";
import React, { Fragment, useState, useContext } from "react";
import { YearTemplateQuery } from "../../graphql-schema-types";
import {
  AwardCategoryHeading,
  MainCategoryHeading,
} from "../components/Atoms/CategoryHeading";
import { Heading1 } from "../components/Atoms/Text";
import {
  EntryPresentation,
  HostSpeakerAndJury,
} from "../components/Organisms/SharedAwardPresentation";
import { extractYearData } from "../formatModels";
import {
  PersonSearchContext,
} from "../components/Organisms/PersonSearchBar";
import { css } from "@emotion/core";
import { Button } from "../components/Atoms/Button";
import SideMenu from "../components/Organisms/SideMenu";
import YearSelectMenu from "../components/Organisms/YearSelectMenu";
import { bpMedium, bpLarge } from "../stylingConstants";
import NewsListItem from "../components/Atoms/NewsListItem";
import {
  verticalMargin,
  horizontalMargin,
  verticalPadding,
  horizontalPadding,
} from "../margins";
import Seo from "../components/Seo";
import { trimYearPrefix } from "../polymorphic-utils";
import { CategoryOrRolePickerWithButton } from "../components/Organisms/CategoryPicker";

type Props = {
  context: {};
  data: YearTemplateQuery;
  pageContext: { categories: [string, string][] };
};

export default function Year({
  data: { contentfulYear },
  pageContext: { categories },
}: Props) {
  if (contentfulYear == null) {
    throw Error("Can't render year template with null yeardata");
  }
  const yearData = extractYearData(contentfulYear);
  const [showYearSelectNav, setShowYearSelectNav] = useState(false);
  const { activate: activatePersonSearch } = useContext(PersonSearchContext);
  return (
    <>
      <Seo title={`Vinnare ${yearData.year}`} />
      <SideMenu
        show={showYearSelectNav}
        onClose={() => setShowYearSelectNav(false)}
      >
        <YearSelectMenu selectedOption={yearData.year} />
      </SideMenu>
      <div
        css={css`
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        `}
      >
        <Heading1>{yearData.year}</Heading1>
        <div
          css={css`
            visibility: visible;
            @media ${bpMedium} {
              visibility: hidden;
            }
          `}
        >
          <Button
            css={{ marginRight: 8 }}
            onClick={() => setShowYearSelectNav(true)}
          >
            Välj år
          </Button>
          <CategoryOrRolePickerWithButton
            categories={categories}
            buttonProps={{ css: { marginRight: 8 } }}
          />
          <Button
            onClick={() => {
              activatePersonSearch();
            }}
          >
            Sök
          </Button>
        </div>
      </div>
      {yearData.awards.map(({ winner, nominees, ...award }) => (
        <Fragment key={award.title}>
          <AwardCategoryHeading>{award.title}</AwardCategoryHeading>
          {winner && <EntryPresentation type="winner" data={winner} />}
          {nominees.map((nominee) => (
            <EntryPresentation type="nominated" data={nominee} />
          ))}
        </Fragment>
      ))}
      <HostSpeakerAndJury year={yearData} juryRowCount={5} deemphasizedHeadings={true} />
      {yearData.articles && yearData.articles.length && (
        <>
          <MainCategoryHeading>Händelser</MainCategoryHeading>
          <section
            css={css`
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              flex-wrap: wrap;
              @media ${bpMedium} {
                ${verticalMargin(-31)}
                ${horizontalMargin(-16.5)}
              }
              @media ${bpLarge} {
                ${horizontalMargin(-24)}
              }
            `}
          >
            {yearData.articles?.map((article) => {
              if (article == null) {
                return null;
              }
              return (
                <NewsListItem
                  css={css`
                    @media ${bpMedium} {
                      width: ${100 / 3}%;
                      box-sizing: border-box;
                      @media ${bpMedium} {
                        ${verticalMargin(0)}
                        ${verticalPadding(31)}
                        ${horizontalPadding(16.5)}
                      }
                      @media ${bpLarge} {
                        ${horizontalPadding(24)}
                      }
                    }
                  `}
                  href={`${yearData.year}/${article.slug}`}
                  imageSrc={article.image?.fluid}
                  title={trimYearPrefix(article.title)}
                  publishDate={article?.publishDate}
                  preamble={article.preamble?.preamble}
                />
              );
            })}
          </section>
        </>
      )}
    </>
  );
}

export const query = graphql`
  query YearTemplate($year: Date) {
    contentfulYear(year: { eq: $year }) {
      year
      ...YearSelection
    }
  }
`;
